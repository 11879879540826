.filterHeader {
  color: var(--color-text);
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: #2a313b;
}

.filterContent {
  margin: auto;
  color: var(--color-text);
  padding: 20px;
  text-align: left;
  margin: 0px auto;
  background-color: #161c24;
}

.filterSelection{
  display: flex;
  margin-bottom: 45px;
}

.byIsGreen {
  margin-bottom: 45px;
}

.filterDescriptor {
  margin-right: 10px;
  align-items: center;
}

.searchForm {
  display: flex;
  flex-direction: column; 
  align-items: stretch; 
}

.button_8 {
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #265353;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  padding: 2px .4em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.button-8:hover,
.button-8:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

.button-8:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}