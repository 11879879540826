.loginContainer {
  margin: 0px auto 0px 5px;
  width: 25%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  z-index: 11;
}

.loginHeader {
  margin-top: 0px;
  text-align: center;
}

.loginForm {
  margin: 20px auto 5px;
}

.formSection {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formLabel {
  margin-right: 10px;
}

.formInput {
  width: 65%;
}

.loginButtonDiv {
  margin-top: 20px;
  text-align: center;
}

.loginButton,
.signUpButton {
  width: 40%;
  margin-bottom: 10px;
  cursor: pointer;
}

.toggleButtonDiv {
  width: 100%;
  text-align: center;
}

.toggleButton {
  cursor: pointer;
  background: none; 
  border: none; 
  color: #007bff; 
  text-decoration: underline; 
  font-size: 12px; 
}