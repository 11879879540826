.alertSlipItem {
  background-color: rgba(255, 255, 255, 0.913);
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
}

.thresholdInput {
  background-color: rgba(255, 255, 255, 0.913);
  border-color: rgb(0, 0, 0);
  border: 1px solid;
}

.removeButton {
  position: absolute;
  top: 5px; 
  right: 10px;
  margin-left: 5px;
  color: var(--color-alert-color);
  cursor: pointer;
  font-size: 20px; 
  font-weight: bold;
}

.alertTitle {
  font-size: 19px;
  margin: 0 0 5px 0;
}

.alertDetails {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.alertSetUp {
  font-size: 12px;
  margin: 0;
  padding: 2px;;
}

.gameSetUp {
  font-size: 12px;
  margin: 0;
  padding: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}