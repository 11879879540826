  .parlayCard {
    cursor: pointer;
    width: 100%;
    color: var(--color-text);
    background-color: var(--color-secondary-background);
    display: grid;
    grid-template-columns: 2fr 1fr 1.25fr; 
    border-bottom: 1px solid #404041;
    box-sizing: border-box;
  
  }
  
  .parlayCardHeader {
    display: contents;
    font-size: 15px;
  }
  
  .parlayCardContent {
    background-color: #2c3e50;
    grid-column: 1 / -1; 
  }
  
  .parlayName {
    display: flex; 
    justify-content: flex-start; 
    align-items: center; 
    padding-left: 20px; 
    flex-grow: 1;
    padding: 15px 15px;
  }
  
  .winningBets {
    display: flex;
    justify-content: flex-start; 
    align-items: center; 
    flex-grow: 1;
  }
  
  .buttons {
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
    flex-grow: 1; 
    padding-right: 10px;
  }
  
  .clickExpandText {
    font-size: 10px;
  }
  
  .inputEditDiv {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 18px;
    padding: 15px 15px;
  }
  
  .inputEdit {
    background-color: var(--color-secondary-background);
    border: 1px solid #2a3947; 
    color: #fff; 
    width: calc(100% - 100px); 
    margin-left: 5px;
  }
  
  .saveButtonDiv {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  
  .saveButton {
    border: none;
    text-align: center;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px; 
    width: 50%;
    height: 33%;
  }
  
  .button_8 {
    background-color: #e1ecf4;
    border-radius: 3px;
    border: 1px solid #265353;
    box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    padding: 2px .4em;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
    white-space: nowrap;
  }
  
  .button-8:hover,
  .button-8:focus {
    background-color: #b3d3ea;
    color: #2c5777;
  }
  
  .button-8:active {
    background-color: #a0c7e4;
    box-shadow: none;
    color: #2c5777;
  }