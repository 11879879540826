.headerContainer {
  display: flex;
  justify-content: center; 
  margin: 10px 0px 0px;
}

.search {
  margin-right: 205px;
  text-align: end;
}

.pageLayout {
  display: flex;
}

.mainContent {
  flex-grow: 1;
  margin: 0px 20px 150px 0px;
  max-width: 87%;
}

.navBarContainer {
  margin: 0px 0px 150px 0px;
  min-height: 100vh;
  width: 13%;
}

.gamesAndAlerts {
  display: flex; 
  margin-left: 5px;
}

.gamesContainer {
  width: 70%;
}

.alertSlipContainerProps {
  position: fixed;
  right: 15px;
  margin: 0px 0px 20px;
  height: 70%;
  max-height: 550px;
  width: 25%;
}