.header {
  width: 100%;
  height: 100px;
  display: flex;
}

.logo {
  max-height: 100px;
  max-width: 100px;
  margin-right: calc(13% - 100px);
}

.logoImage {
  height: 100%;
  width: 100%;
}

.text {
  margin: auto;
  display: flex;
  justify-content: center;
}

.emptyDiv {
  margin-right: calc((87% * .25) - 25px);
  height: 100px;
  width: 100px;
}

.leagueName {
  color: var(--color-vibrant-text);
  font-size: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.livebet {
  color: var(--color-vibrant-text);
  font-size: 35px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.alert {
  color: var(--color-alert-color);
  font-size: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}
