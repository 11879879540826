.gameSearchContainer {
    display: flex; 
    align-items: flex-start; 
    justify-content: flex-end; 
    height: 25px;
}

.searchForm {
    display: flex; 
    align-items: center; 
}

.searchText {
    color: var(--color-text);
    margin-right: 10px;
}
