.headerContainer {
  display: flex;
  justify-content: center; 
  margin: 10px auto 25px;
}

.pageLayout {
  display: flex;
}

.mainContent {
  flex-grow: 1;
  margin: 0px 20px 150px 0px;
  max-width: 87%;
}

.navBarContainer {
  margin: 0px 0px 150px 0px;
  min-height: 100vh;
  width: 13%;
}

.alertsAndFilter {
  display: flex; 
  gap: 20px; 
  margin-left: 5px;
}

.alertsContainer {
  width: 70%;
}

.alertsFilterContainer {
  position: fixed;
  right: 15px;
  margin: 0px 0px 20px;
  height: 70%;
  max-height: 550px;
  width: 25%; 
}

