.playerPropCard {
  color: var(--color-text);
  display: flex;
  grid-column: 1 / span 5;
  padding: 5px 15px 5px;
  margin: 0px;
  width: 100%;
  background-color: var(--color-secondary-background);
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #404041;
  box-sizing: border-box;
  justify-content: center;
}

.valueDiv {
  display: flex;
  justify-content: center;
}

.pregameValue {
  font-size: 14px;
}

.actionButton {
  padding: 5px 10px;
  text-align: center;
  background-color: #57d5d7;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: auto;
  width: fit-content;
}

.playerName {
  text-align: left;
  padding-left: 15px;
}

.propValue {
  display: block;
  margin-bottom: 2px;
}

.vigValue {
  font-size: 12px;
}

.clickableDiv {
  cursor: pointer; 
  border: 1px solid #7a7a7a; 
  padding: 5px; 
  margin: 2px; 
  border-radius: 4px; 
  text-align: center; 
  transition: background-color 0.3s, border-color 0.3s; 
  width: 40%;
}

.clickableDiv:hover {
  background-color: #a5a5a5; 
  border-color: #bbb; 
}