.teamCard {
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 0.25fr 1fr 0.5fr 0.5fr 0.5fr;
  align-items: center;
}

.teamName {
  padding: 3px 5px 5px 5px;
  margin-left: 15px;
}

.teamScore,
.pregameOdds {
  text-align: center; 
  font-size: 14px;
}

.currentOdds {
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  border: 1px solid #7a7a7a; 
  padding: 5px;
  margin: 2px; 
  border-radius: 4px; 

}

.clickableDiv {
  cursor: pointer; 
}

.clickableDiv:hover {
  background-color: #a5a5a5; 
  border-color: #bbb; 
}

.teamInfo {
  display: flex;
  font-size: 14px;
  align-items: center;
}

.logo {
  width: 30px;
  height: 30px;
}

.logoImage {
  max-height: 25px;
  width: auto;
}
