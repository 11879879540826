.propsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;
}

.headersDiv {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: var(--color-background);
}

.displayHeader {
  position: sticky;
  top: 0; 
  z-index: 10; 
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr;
  text-align: center; 
  font-weight: bold;
  font-size: 14px;
  color: var(--color-vibrant-text);
  padding: 15px 15px 15px;
  width: 100%;
  background-color: var(--color-secondary-background);
  border-bottom: 2px solid var(--color-background);
  box-sizing: border-box;
}

.gameData {
  width: 100%;
  color: var(--color-text);
  text-align: center;
  justify-content: flex-end;
  margin-bottom: 30px;
  background-color: var(--color-secondary-background);
}

.noPropsMessage {
  justify-content: center;
  text-align: center;
  color: var(--color-text);
  width: 100%;
  margin-top: 50px;
}

.scoreDiv {
  justify-content: center;
}



.gameDisplayHeader {
  position: sticky;
  top: 0; 
  z-index: 10; 
  display: grid;
  grid-template-columns: 1fr 0.25fr 1fr 0.5fr 0.5fr 0.5fr;
  text-align: center; 
  font-weight: bold;
  font-size: 14px;
  color: var(--color-vibrant-text);
  padding: 15px 15px 15px;
  background-color: var(--color-secondary-background);
  border-bottom: 2px solid var(--color-background);
  width: 100%;
  box-sizing: border-box;
}
