.navbar {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-navbar-background);
}

.navbar button {
  font-family: 'Barlow', 'sans serif';
  background-color: var(--color-navbar-background);
}

.navbar a,
.navbar button {
  color: white;
  padding: 15px 0px 10px 10px;
  text-decoration: none;
  font-size: 16px; 
  border: none; 
  display: block;
  cursor: pointer;
  text-align: left;
}

.navbar a:hover,
.navbar button:hover {
  background-color: #34495e; 
  color: #ecf0f1; 
}

.navbar .sportsbookDropdownMenu {
  background-color: #2c3e50; 
  margin: 0px;
  padding: 0px;
}


.navbar .sportsbookDropdownMenu li {
  padding: 5px 15px 5px;
  color: white;
  cursor: pointer;
}


.navbar .sportsbookDropdownMenu li:hover {
  background-color: #34495e; 
}


.activeSportsbook {
  background-color: #596d7f;  
}

.navbar .activeLink {
  background-color: #34495e; 
}