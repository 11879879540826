.gameCard {
  padding: 15px 15px;
  background-color: var(--color-secondary-background);
  grid-template-columns: 1fr 0.25fr 1fr 0.5fr 0.5fr 0.5fr;
  border-bottom: 1px solid #404041;
  width: 100%;
  box-sizing: border-box;
  color: var(--color-text);
}

.teamCardsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gameFooter {
  justify-content: space-between;
  padding: 5px;
  display: flex;
}

.gameTime,
.playerProps {
  font-size: 11px;
  color: #666;
  margin: 0;
  text-decoration: none;
}
