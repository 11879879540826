.headerContainer {
  display: flex;
  justify-content: center; 
  align-items: center; 
  margin: 10px auto 25px; 
}

h1 {
  text-align: center;
  margin-top: 0px;
}

.pageLayout {
  display: flex;
}

.mainContent {
  flex-grow: 1;
  margin: 0px 20px 150px 0px;
  max-width: 87%;
  color: var(--color-text);
  align-items: center;
}

.carouselDiv {
  width: 85%;
  padding: 10px 10px 0px;
  margin: 0 auto; 
  box-shadow: 0 0 10px 1px var(--color-vibrant-text); /* Adjust the values as needed */
}


.carouselDiv .carousel {
  max-width: 75%;
}

.carouselDiv .carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro {
  margin: 0px 20px 100px;
  text-align: center;
}

.intro h2 {
  margin-bottom: 50px;
}

.intro h3 {
  color: var(--color-text);
}

.partOne {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin: 0px 20px;
  flex-direction: column; 
  text-align: left;
}

.partOne p{
  width: 100%;
  margin: 10px 0px 25px 25px;
}

.partOne img {
  width: 100%;
  margin-bottom: 50px;
}

.partOne video {
  width: 100%;
  margin-bottom: 50px;
}

.partOne h3 {
  margin: 10px 0px 0px;
}


.navBarContainer {
  margin: 0px 0px 150px 0px;
  min-height: 100vh;
  min-width: 13%;
}

.header {
  width: 100%;
  height: 100px;
  display: flex;
}

.logo {
  max-height: 100px;
  max-width: 100px;
  margin-right: calc(13% - 100px);
}

.logoImage {
  height: 100%;
  width: 100%;
}

.text {
  margin: auto;
  display: flex;
  justify-content: center;
}

.leagueName {
  color: var(--color-vibrant-text);
  font-size: 35px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.livebet {
  color: var(--color-vibrant-text);
  font-size: 35px;
  align-items: center;
  justify-content: center;
  display: flex;

}

.alert {
  color: var(--color-alert-color);
  font-size: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.endDiv {
  margin: 25px 100px 0px;
}