.alertSlipContainer {
  height: 100%;
  width: 100%;
  background-color: var(--color-secondary-background);
}

.alertItemsContainer {
  margin-bottom: 60px;
  height: 81%;
  overflow-y: auto;
}

.alertItemsContainer::-webkit-scrollbar {
  width: 4px;
}

.alertItemsContainer::-webkit-scrollbar-track {
  background: var(--color-secondary-background);
}

.alertItemsContainer::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 4px; 
}

.alertItemsContainer::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.noAlertsMessage {
  text-align: center;
  color: #666;
  margin-top: 35px;
}

.alertSlipHeader {
  color: var(--color-text);
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: var(--color-tertiary-background);
}

.parlayBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--color-tertiary-background);
  color: var(--color-text);
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.buttonDiv {
  display: flex;
  gap: 10px;
}

.parlayCheckboxLabel {
  margin-right: 60px; 
  cursor: pointer;
  font-size: 14px; 
}

.parlayCheckbox {
  margin-right: 5px; 
  cursor: pointer;
}
