.currentAlertCard {
  padding: 15px 15px;
  width: 100%;
  background-color: var(--color-secondary-background);
  display: grid;
  grid-template-columns: 1fr 0.25fr 0.5fr 0.5fr 0.5fr 0.5fr;
  border-bottom: 1px solid #404041;
  box-sizing: border-box;
  text-align: center;
  color: var(--color-text);
  
}

.name {
  text-align: left; 
  display: flex;
  align-items: center; 
  padding-left: 5px;
}

.logo {
  margin-right: 5px; 
}

.logoImage {
  height: 20px; 
  width: 20px; 
  margin-right: 5px; 
}

.indivName {
  text-align: left;
  margin-left: 5px;
}
.gameStatusOrTime {
  font-size: 0.6rem; 
  color: var(--color-text); 
  margin-left: 5px;
}

.button_8 {
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #265353;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  padding: 2px .4em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
  height: 22px;
}

.button-8:hover,
.button-8:focus {
  background-color: #b3d3ea;
  color: #2c5777;
}

.button-8:active {
  background-color: #a0c7e4;
  box-shadow: none;
  color: #2c5777;
}

.alertInfo {
  display: flex;
  flex-direction: column; 
  align-items: left; 
  text-align: left; 
}

.logoAndName {
  display: flex;
  flex-direction: row; 
  align-items: left; 
  text-align: left;
}
