:root { 
    --color-background: #0b0c10;
    --color-secondary-background: #161c24;
    --color-tertiary-background: #2a313b;
    --color-navbar-background: rgb(3, 43, 55);
    --color-text: rgba(255,255,255,0.618);
    --color-vibrant-text: #45a29e;
    --color-alert-color: rgb(152, 2, 2); 

}


body, html {
    font-family: 'Barlow', sans-serif;
  }