.headerContainer {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: 120px; 
  scrollbar-color: #888 #e0e0e0; 
  margin-bottom: 25px;
  background-color: var(--color-background);
  align-items: center; 
  overflow-y: hidden;
}


.propLink {
  width: 100px;
  padding: 5px 0px;
  font-size: 13px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 40px;
}

.headerContainer::-webkit-scrollbar {
  height: 10px;
}

.headerContainer::-webkit-scrollbar-thumb {
  background-color: rgb(91, 83, 83); 
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.851);
}

.headerContainer::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.851);
  
}

.headerContainer a {
  text-decoration: none; 
  color: var(--color-text);
  transition: background-color 0.3s; 
  padding: 0px 10px;
  margin-bottom: 15px;
}

.headerContainer a:active {
  transform: translateY(2px); 
}

.headerContainer .activeLink {
  border-bottom: 3px solid #404041;
}

.link {
  text-decoration: none;
  color: #ffffff;
  padding: 5px 10px; 
  margin-right: 8px; 
}