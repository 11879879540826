.gamesContainer {
  width: 100%;
}

.displayHeader {
  position: sticky;
  top: 0; 
  z-index: 10; 
  display: grid;
  grid-template-columns: 1fr 0.25fr 1fr 0.5fr 0.5fr 0.5fr;
  text-align: center; 
  font-weight: bold;
  font-size: 14px;
  color: var(--color-vibrant-text);
  padding: 15px 15px 15px;
  background-color: var(--color-secondary-background);
  border-bottom: 2px solid var(--color-background);
}

.noGamesMessage {
  margin: 25px;
  text-align: center;
  color: var(--color-text);
}